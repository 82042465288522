<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard>
          <CForm @submit.prevent="submit">
            <CCardHeader>
              <strong>Editar curso</strong>
            </CCardHeader>
            <CCardBody>
              <CInput
                placeholder="Introduce el título del curso"
                v-model="form.title"
                label="Título del curso"
                required
              />
              <CTextarea
                label="Descripción del curso"
                placeholder=""
                vertical
                rows="3"
                v-model="form.description"
                required
              />
              <label for="formFile">Imagen del curso</label> <br>
              <img v-bind:src="form.thumbnail" alt="" width="200px"><br>
              <input 
                type="file" 
                @change="onFileUpload" 
              />
              <br>
              <br>
              Curso activo? <br>
              <CSwitch
                class="mr-1"
                color="primary"
                :checked="Boolean(form.active)"
                v-model="form.active"
                @update:checked="() => form.active = !form.active"
              />
            </CCardBody>
            <CCardFooter>
              <CButton color="danger" @click="() => $router.push('/course/admin')">
                    Regresar
                </CButton>
                <CButton class="float-right" color="primary" type="submit">
                    Guardar
                </CButton>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import axios from 'axios'
import store from '@/store'
export default {
  name: 'CreateCourse',
  data: function () {
    return {
      form: {
        title: '',
        description: '',
        picture: null
      },
      thumbnail: null,
      show: true,
      isCollapsed: true,
    }
  },
  mounted() {
      const token = store.state.user.token;
      axios
        .get(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
            this.form = response.data.data;
        })
  },
  methods: {
    onFileUpload(event) {
        this.form.picture = event.target.files[0]
    },
    submit() {
      
      const token = store.state.user.token;
      console.log(this.form);
      const formData = new FormData();
      if (this.form.picture) {
        
      formData.append('picture', this.form.picture, this.form.picture.name)
      }
      formData.append('title', this.form.title)
      formData.append('description', this.form.description)
      axios
        .put(process.env.VUE_APP_BACKEND_URL+'/api/course/' + this.$route.params.id, this.form, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
        })
        .then(response => {
          this.$router.push('/course/' + this.$route.params.id + '/lessons/create')
        })
    }
  }
}
</script>
